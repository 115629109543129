<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-form ref="form" v-model="is_valid" lazy-validation>
      <v-card>
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("change_password") }}</span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  type="password"
                  :label="$t('old_password')"
                  v-model="user.old_password"
                  :rules="[rules.required, rules.min_8]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  type="password"
                  :label="$t('new_password')"
                  v-model="user.new_password"
                  :rules="[rules.required, rules.min_8]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  outlined
                  type="password"
                  :label="$t('confirm_password')"
                  v-model="user.confirm_password"
                  :rules="[
                    rules.required,
                    rules.same_as_password(user.new_password),
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            color="primary"
            text
            @click="save(user)"
            :disabled="!is_valid"
            :loading="is_loading_save"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      isLoadingAttributes: "attributes/isLoadingAttributes",
      listAttributes: "attributes/listAttributes",
      listStatuses: "statuses/listStatuses",
    }),
  },
  data() {
    return {
      user: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      is_valid: true,
      is_loading_save: false,
    };
  },

  methods: {
    async save(item) {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("updatePassword", item);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
  },
};
</script>
